// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or any plugin's vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require jquery
//= require jquery_ujs
//= require cocoon

//= require ./canvas-shifter
//= require_tree ./behaviours

//= require admino.jquery

import Rails from '@rails/ujs'
import 'cocoon-js'

require('chosen-js/chosen.css')
require('lightslider/src/css/lightslider.css')
require('magnific-popup/dist/magnific-popup.css')

Rails.start()

require('../src/canvas-shifter.js.coffee')
require('../src/behaviours/admino.jquery.js.coffee')
require('../src/behaviours/behaviour.js.coffee')
require('../src/behaviours/collapsable.js.coffee')
require('../src/behaviours/contract_select.js.coffee')
require('../src/behaviours/date_picker.js.coffee')
require('../src/behaviours/document_uploader.js.coffee')
require('../src/behaviours/filter_form.js.coffee')
require('../src/behaviours/image_slider.js.coffee')
require('../src/behaviours/image_uploader.js.coffee')
require('../src/behaviours/modal.js.coffee')
require('../src/behaviours/modal_image_gallery.js.coffee')
require('../src/behaviours/multi_document_upload.js.coffee')
require('../src/behaviours/multi_upload.js.coffee')
require('../src/behaviours/price_formatter.js.coffee')
require('../src/behaviours/select.js.coffee')
require('../src/behaviours/select_toggler.js.coffee')
require('../src/behaviours/sortable_images.js.coffee')
require('../src/behaviours/sticky_head.js.coffee')
require('../src/behaviours/tab_handle.js.coffee')
require('../src/behaviours/update_unit_code.js.coffee')
